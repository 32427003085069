/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from "react";
import PropTypes, { string } from "prop-types";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Link from "../LinkWithCountry/LinkWithCountry";

import * as elementsActions from "../../redux/actions/elements";
import {
  hasPermission,
  isMobileScreen,
  routeCountryPath
} from "../../shared/utilities/common";
import { renderSocialLinksSection } from "../../shared/utilities/renderings";
import { guardOrderChange } from "../../shared/utilities/orders";
import endpoints from "../../shared/constants/endpoints";
import { orderMethods } from "../../shared/constants/order";
import siteSetting from "../../shared/constants/siteSetting";

// GRAPHQL
import * as doughProRepo from "../../shared/repos/graphql/doughPro";

// UTILITIES
import styles from "./MobileMenu.module.css";
import * as userActions from "../../redux/actions/user";
import { METADATA_INFO } from "../../shared/constants/common";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";
import { PUNCHH } from "../../shared/constants/loyaltyType";
import { LABELS } from "../../shared/constants/labels";
import CmsEditSwitch from "../Cms/CmsEditSwitch/CmsEditSwitch";
import permissionTypes from "../../shared/constants/permissionTypes";

const getYear = () => new Date().getFullYear();
const exitIcon = require("./imgs/exitIcon.svg");
const exitLightIcon = require("./imgs/exitIconLight.svg");
const barcodeIcon = require("./imgs/barcode.svg");
const barcodeLightIcon = require("./imgs/barcode-light.svg");

class MobileMenu extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false
    };
  }

  componentDidMount() {
    const { isBetaCustomer, isDoughProActive, doughProMember } = this.props;
    doughProRepo.doughProFreeCookie().then(response => {
      const { doughProFreeCookie } = response.data;
      const currentState = this.state;
      const isActive =
        (isBetaCustomer || isDoughProActive) &&
        doughProMember &&
        doughProMember.active &&
        doughProFreeCookie === null;
      this.setState({
        ...currentState,
        isActive
      });
    });
  }

  /**
   * Renders link label element based on path and label provided
   * @param {String} path - url path of the resulting link
   * @param {String} label - label of the resulting link
   * @returns {JSX.Element} - returns formatted Link text element
   */
  renderLink = (path, label) => {
    const { toggleMobileMenu } = this.props;
    const uri = path.replace("/", "");
    return (
      <Link
        onClick={toggleMobileMenu}
        className={`link__page__${uri}`}
        to={path}
      >
        <h4>{label}</h4>
      </Link>
    );
  };

  isStickyCategoriesEnabled = () => {
    const { location } = this.props;

    return location === "/" || location.includes(endpoints.getMenuUrl(null));
  };

  handleMenuClick = e => {
    const { toggleMobileMenu } = this.props;
    toggleMobileMenu();
    if (this.isStickyCategoriesEnabled()) e.preventDefault();
  };

  visitShippingPage = e => {
    const { history, userOrderMethod, toggleMobileMenu } = this.props;

    e.preventDefault();

    toggleMobileMenu();

    const currentOrderNotShipping = userOrderMethod
      ? userOrderMethod !== orderMethods.shipping
      : false;

    guardOrderChange(
      currentOrderNotShipping,
      history.push,
      routeCountryPath(endpoints.shippingPage)
    );
  };

  handleStoreMenu = e => {
    e.preventDefault();
    const { history, selectedStore, toggleMobileMenu } = this.props;

    toggleMobileMenu();
    const menuLink = endpoints.getMenuUrl(selectedStore);

    history.push(routeCountryPath(menuLink));
  };

  visitDoughProPage = e => {
    const { history, toggleMobileMenu } = this.props;

    e.preventDefault();

    toggleMobileMenu();

    history.push(routeCountryPath(endpoints.doughProPage));
  };

  visitRewardLandingPage = e => {
    const { history, toggleMobileMenu } = this.props;

    e.preventDefault();

    toggleMobileMenu();

    history.push(routeCountryPath(endpoints.rewardLandingPage));
  };

  visitDoughProFreeCookiePage = e => {
    const { history, userOrderMethod, toggleMobileMenu } = this.props;

    e.preventDefault();

    toggleMobileMenu();

    const currentOrderNotShipping = userOrderMethod
      ? userOrderMethod !== orderMethods.doughPro
      : false;

    guardOrderChange(
      currentOrderNotShipping,
      history.push,
      routeCountryPath(endpoints.doughProFreeCookiePage)
    );
  };

  showProfileBarcode = e => {
    e.preventDefault();
    const { toggleBarcodeCard, toggleMobileMenu } = this.props;
    loyaltyRepo.getLoyaltyProfile().then(response => {
      toggleBarcodeCard({
        showBarcodeCard: true,
        value: response.data?.loyaltyProfile?.qrCode
      });
      toggleMobileMenu();
    });
  };

  render() {
    const {
      toggleMobileMenu,
      isBetaCustomer,
      isDoughProActive,
      isCountrySelectActive,
      selectedCountry,
      tiktokLink,
      instagramLink,
      facebookLink,
      footerMenuItemsSettings,
      footerMenuItemsLinksSettings,
      isGiftcardDisabled,
      userToken,
      hasActiveOrders,
      themeSettings,
      activeLoyalty,
      permissions,
    } = this.props;
    
    const canEditCms = hasPermission(
      permissions,
      permissionTypes.cms.category,
      permissionTypes.cms.permissions.edit_values
    );

    const { isActive } = this.state;
    const userLoggedIn = (userToken && (isBetaCustomer || isDoughProActive));
    return (
      <div
        className={`${styles.mobileMenuWrapper}
        dark:bg-gradient-from-tl dark:bg-gradient-to-br dark:to-darkBlack dark:from-dark  text-dark dark:text-white`}
      >
        <div className={styles.mobileMenuContainer}>
          <img
            onClick={toggleMobileMenu}
            alt="exit-icon"
            src={exitIcon}
            className="dark:hidden"
          />
          <img
            onClick={toggleMobileMenu}
            alt="exit-icon"
            src={exitLightIcon}
            className="hidden dark:block"
          />
          <div>
            <a
              href={endpoints.shippingPage}
              onClick={e => this.handleStoreMenu(e)}
            >
              <h4>Menu</h4>
            </a>

            {this.renderLink(
              endpoints.locations,
              METADATA_INFO.HEADER_LINK.LOCATION
            )}

            {userLoggedIn && (
              <a href={endpoints.orders}>
                <h4>
                  {LABELS.ORDERS}

                  {hasActiveOrders && (
                    <span className={`${styles.ordersAttentionGrabber}`} />
                  )}
                </h4>
              </a>
            )}

            {!userLoggedIn && (
              <a
                href={endpoints.rewardLandingPage}
                onClick={e => this.visitRewardLandingPage(e)}
              >
                <h4>{LABELS.REWARDS}</h4>
              </a>
            )}

            <a href={endpoints.shippingPage}>
              <h4>
                {LABELS.SHIPPING}
              </h4>
            </a>
            {footerMenuItemsSettings[
              siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED
            ] ? (
              <a
                name="careers_link"
                href={
                  footerMenuItemsLinksSettings[
                    siteSetting.FOOTER_MENU_CAREERS_LINK
                  ]
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Careers</h4>
              </a>
            ) : null}

            {Number(isGiftcardDisabled) === 1 ? null : (
              <a
                href={endpoints.giftCardOrderPage}
                name="gift_card"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Gift Cards</h4>
              </a>
            )}

            {userToken && activeLoyalty === PUNCHH ? (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={e => this.showProfileBarcode(e)}
                onClick={e => this.showProfileBarcode(e)}
                name="scan_barcode"
                className="flex gap-5"
              >
                <h4>Scan Barcode</h4>
                <img
                  alt="barcode-icon"
                  src={barcodeIcon}
                  className="dark:hidden"
                />
                <img
                  alt="barcode-icon"
                  src={barcodeLightIcon}
                  className="hidden dark:block"
                />
              </div>
            ) : (
              <a
                href={endpoints.rewardLandingPage}
                name="insomnia_reward"
                target="_blank"
                rel="noopener noreferrer"
                className="flex gap-5"
              >
                <h4>Insomnia Rewards</h4>
              </a>
            )}

            {isMobileScreen() && isActive === true ? (
              <button
                className={styles.getMyFreeCookieBtn}
                type="button"
                onClick={e => this.visitDoughProFreeCookiePage(e)}
              >
                Get My Free Cookie
              </button>
            ) : null}
          </div>
          {themeSettings[siteSetting.IS_THEME_SWITCH_ENABLED] && (
            <div className={`${styles.mobileMenuFooter} w-full`}>
              <div className="flex gap-2 items-center w-full">
                <p>Theme</p>
                <div className="">
                  <ThemeSwitch />
                </div>
              </div>
            </div>
          )}
          {
            canEditCms && (
              <CmsEditSwitch showOnMobileView />
            )
          }
          <div className={`${styles.mobileMenuFooter} flex gap-2`}>
            <div className="flex flex-col gap-3">
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED
              ] ? (
                <a
                  href={
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_NEWSROOM_LINK
                    ]
                  }
                >
                  <h4>Newsroom</h4>
                </a>
              ) : null}
              {footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_FAQ_ENABLED]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_FAQ_LINK
                    ],
                    "FAQ"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED
              ] ? (
                <a
                  href={endpoints.salesSite}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>Corporate Sales</h4>
                </a>
              ) : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED
              ]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_PRIVACY_POLICY_LINK
                    ],
                    "Privacy Policy"
                  )
                : null}
              {footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_TERMS_ENABLED]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_TERMS_LINK
                    ],
                    "Terms & Conditions"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting
                  .IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED
              ]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting
                        .FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK
                    ],
                    "Promotions Terms & Conditions"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED
              ]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_ACCESSIBILITY_LINK
                    ],
                    "Accessibility"
                  )
                : null}
            </div>
            <div className="flex flex-col gap-3">
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED
              ]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_CONTACT_LINK
                    ],
                    "Contact"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED
              ]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_ABOUT_US_LINK
                    ],
                    "About Us"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED
              ]
                ? this.renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_ALLERGEN_LINK
                    ],
                    "Allergen & Nutrition Info"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED
              ] ? (
                <a href={endpoints.careerSite}>
                  <h4>Careers</h4>
                </a>
              ) : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED
              ] ? (
                <a
                  href={
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_DONATIONS_LINK
                    ]
                  }
                >
                  <h4>Donations</h4>
                </a>
              ) : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_MERCH_ENABLED
              ] ? (
                <a
                  href={
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_MERCH_LINK
                    ]
                  }
                >
                  <h4>Merch</h4>
                </a>
              ) : null}
              {isCountrySelectActive
                ? this.renderLink(
                    "/countries",
                    `Countries${
                      selectedCountry && selectedCountry.short_name
                        ? ` (${selectedCountry.short_name})`
                        : ""
                    }`
                  )
                : ""}
            </div>
          </div>
          <div className="flex flex-col items-center gap-2">
            {renderSocialLinksSection(styles.mobileMenuSocial, {
              tiktokLink,
              instagramLink,
              facebookLink
            })}
            <span className="text-xs">{`insomnia cookies warm.delicious.delivered. Ⓒ ${getYear()}`}</span>
          </div>
        </div>
      </div>
    );
  }
}

MobileMenu.propTypes = {
  permissions: PropTypes.shape({category: string, value: string }).isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  userOrderMethod: PropTypes.string.isRequired,
  isBetaCustomer: PropTypes.bool.isRequired,
  isDoughProActive: PropTypes.bool,
  doughProMember: PropTypes.shape({
    active: PropTypes.bool.isRequired
  }),
  isCountrySelectActive: PropTypes.bool,
  selectedCountry: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    path_url: PropTypes.string,
    api_url: PropTypes.string,
    short_name: PropTypes.string,
    url: PropTypes.string,
    zone_id: PropTypes.string,
    currency: {
      code: PropTypes.string,
      name: PropTypes.string,
      symbol: PropTypes.string
    }
  }).isRequired,
  tiktokLink: PropTypes.string,
  instagramLink: PropTypes.string,
  facebookLink: PropTypes.string,
  footerMenuItemsSettings: PropTypes.shape({
    isNewsroomEnabled: PropTypes.bool,
    isFaqEnabled: PropTypes.bool,
    isPrivacyPolicyEnabled: PropTypes.bool,
    isTermsEnabled: PropTypes.bool,
    isEventTermsEnabled: PropTypes.bool,
    isAccessibilityEnabled: PropTypes.bool,
    isContactEnabled: PropTypes.bool,
    isAboutUsEnabled: PropTypes.bool,
    isCareersEnabled: PropTypes.bool,
    isCorporateSalesEnabled: PropTypes.bool,
    isDonationsEnabled: PropTypes.bool,
    isMerchEnabled: PropTypes.bool
  }).isRequired,
  footerMenuItemsLinksSettings: PropTypes.shape({
    newsroomLink: PropTypes.string,
    faqLink: PropTypes.string,
    privacyPolicyLink: PropTypes.string,
    termsLink: PropTypes.string,
    eventTermsLink: PropTypes.string,
    accessibilityLink: PropTypes.string,
    contactLink: PropTypes.string,
    aboutUsLink: PropTypes.string,
    careersLink: PropTypes.string,
    corporateSalesLink: PropTypes.string,
    donationsLink: PropTypes.string,
    merchLink: PropTypes.string,
    promotionsTermsAndConditionsLink: PropTypes.string,
    allergenLink: PropTypes.string
  }).isRequired,
  isGiftcardDisabled: PropTypes.number.isRequired,
  selectedStore: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  userToken: PropTypes.string,
  hasActiveOrders: PropTypes.bool,
  themeSettings: PropTypes.shape({}),
  toggleBarcodeCard: PropTypes.func.isRequired,
  activeLoyalty: PropTypes.bool
};

MobileMenu.defaultProps = {
  isDoughProActive: false,
  doughProMember: null,
  isCountrySelectActive: false,
  tiktokLink: "",
  instagramLink: "",
  facebookLink: "",
  selectedStore: null,
  userToken: null,
  hasActiveOrders: false,
  themeSettings: {},
  activeLoyalty: false
};

export const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(elementsActions.toggleMobileMenu()),
  setDoughProMember: value => dispatch(userActions.setDoughProMember(value)),
  toggleBarcodeCard: value => dispatch(elementsActions.toggleBarcodeCard(value))
});

export const mapStateToProps = state => {
  const {
    userOrderMethod,
    isBetaCustomer,
    doughProMember,
    selectedStore,
    userToken,
    permissions,
  } = state.user;
  const { isDoughProActive } = state.doughPro;
  const { isShippingActive } = state.shipping;
  const { selectedCountry, isCountrySelectActive } = state.country;
  const {
    tiktokLink,
    instagramLink,
    facebookLink,
    footerMenuItemsSettings,
    footerMenuItemsLinksSettings
  } = state.footerSettings;
  const { isGiftcardDisabled } = state.giftCard;
  const { themeSettings } = state.theme;
  const { activeLoyalty } = state.loyalty;
  const { hasActiveOrders } = state.elements;

  return {
    userOrderMethod,
    isBetaCustomer,
    isDoughProActive,
    doughProMember,
    isShippingActive,
    selectedCountry,
    isCountrySelectActive,
    tiktokLink,
    instagramLink,
    facebookLink,
    footerMenuItemsSettings,
    footerMenuItemsLinksSettings,
    isGiftcardDisabled,
    selectedStore,
    userToken,
    hasActiveOrders,
    themeSettings,
    activeLoyalty,
    permissions,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
);
