import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import styles from "./Banner.module.css";

import {
  getContentsByKey,
  getContentValueByKey
} from "../../../shared/utilities/common";

function Banner({ contents, openDeliveryWidget }) {
  const title = getContentValueByKey(contents, "title");
  const description = getContentValueByKey(contents, "description");
  const image = getContentValueByKey(contents, "image");
  const mobileImage = getContentValueByKey(contents, "image_mobile");
  const link = getContentsByKey(contents, "link");
  const linkTitle = getContentValueByKey(link, "title");
  const linkUrl = getContentValueByKey(link, "url");

  return (
    <>
      <div
        className={`${styles.bannerBackground} mx-auto flex items-center justify-around relative max-w-full mt-11`}
      >
        <div
          className=" w-[343px] md:w-[600px] h-[259px] md:h-[194px] pl-4 py-[72px] pr-0 flex 
      flex-col items-center md:items-start gap-3 absolute md:left-[10%] md:top-[10%] top-[35%] text-center md:text-left"
        >
          <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white">
            {title}
          </h1>
          <p className="font-filsonProRegular text-lg leading-5 md:leading-[22px] tracking-[-0.1px] text-white">
            <ReactMarkdown>{description}</ReactMarkdown>
          </p>
          {linkUrl && (
            <button
              onClick={() => openDeliveryWidget(linkUrl)}
              type="button"
              className="bg-button text-lg font-filsonProMedium px-8 text-light my-4 h-[44px] rounded-[54px] "
            >
              {linkTitle}
            </button>
          )}
        </div>
        <ReactMarkdown className="absolute right-0 top-[-128px] md:block hidden">
          {image}
        </ReactMarkdown>

        <ReactMarkdown className="absolute top-[-30%] md:hidden">
          {mobileImage}
        </ReactMarkdown>
      </div>
    </>
  );
}

Banner.propTypes = {
  contents: PropTypes.shape({}),
  openDeliveryWidget: PropTypes.func.isRequired
};

Banner.defaultProps = {
  contents: null
};

export default Banner;
