import moment from 'moment';

export const now = () => {
    return moment().format();
};

export const cleanState = {
    userToken: null,
    selectedStore: null,
    selectedStoreHours: [],
    userOrderMethod: null,
    orderCompleted: false,
    userCart: [],
    userCartId: '',
    userZoneId: null,
    userId: null,
    savedAddresses: [],
    isBetaCustomer: false,
    doughProMember: null,
    location: {
        lat: '',
        lng: ''
    },
    userInfo: {},
    selectedMapStore: {},
    alertRecipientPhone: false,
    alertSenderPhone: true,
    persistedAt: now(),
    lastCompletedOrderCode: null,
    permissions: [],
    cmsEditMode: false,
};

const initialState = {
    ...cleanState
};

export default (state = initialState, { type, value }) => {

    const valueByType = [
        { type: 'SET_USER_TOKEN', value: { ...state, userToken: value, persistedAt: now() }},
        { type: 'SET_SELECTED_STORE', value: { ...state, selectedStore: value }},
        { type: 'SET_SELECTED_STORE_HOURS', value: { ...state, selectedStoreHours: value }},
        { type: 'SET_USER_ORDER_METHOD', value: { ...state, userOrderMethod: value }},
        { type: 'SET_USER_ADDRESS', value: { ...state, userAddress: value }},
        { type: 'SET_USER_ID', value: { ...state, userId: value }},
        { type: 'SET_USER_CART', value: { ...state, userCart: value }},
        { type: 'SET_USER_INFO', value: { ...state, userInfo: value}},
        { type: 'SET_USER_CART_ID', value: { ...state, userCartId: value }},
        { type: 'SET_USER_ZONE_ID', value: { ...state, userZoneId: value }},
        { type: 'SET_ORDER_COMPLETED', value: { ...state, orderCompleted: value }},
        { type: 'SAVE_ADDRESS', value: { ...state, savedAddresses: value }},
        { type: 'SET_ENDPOINT', value: { ...state, endpoint: value }},
        { type: 'SET_USER_LOCATION', value: { ...state, location: value }},
        { type: 'SET_SELECTED_MAP_STORE', value: { ...state, selectedMapStore: value }},
        { type: 'SET_BETA_CUSTOMER', value: { ...state, isBetaCustomer: value }},
        { type: 'SET_DOUGH_PRO_MEMBER', value: { ...state, doughProMember: value }},
        { type: 'SET_ALERT_RECIPIENT_PHONE', value: { ...state, alertRecipientPhone: value }},
        { type: 'SET_ALERT_SENDER_PHONE', value: { ...state, alertSenderPhone: value }},
        { type: 'SET_LAST_COMPLETED_ORDER_CODE', value: { ...state, lastCompletedOrderCode: value }},
        { type: 'RESET', value: {...initialState, lastCompletedOrderCode: state?.lastCompletedOrderCode || null }},
        { type: 'RESET_WITHOUT_TOKEN', value: {...initialState, userToken: state?.userToken || null, lastCompletedOrderCode: state?.lastCompletedOrderCode || null }},
        { type: 'SET_PERMISSIONS', value: { ...state, permissions: value }},
        { type: 'SET_CMS_EDIT_MODE', value: { ...state, cmsEditMode: value }},
    ];

    const selectedType = valueByType.find(item => item.type === type);
    return selectedType ? selectedType.value : state;
};
