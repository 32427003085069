/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./OrderHelp.module.css";
import ReturnSVG from "./imgs/return.svg";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import FeedbackForm from "../../components/FeedbackForm/FeedbackForm";
import FeedbackFormList from "../../components/FeedbackFormList/FeedbackFormList";
import { getFeedbackForms } from "../../shared/repos/graphql/feedbackForm";
import { feedbackFormTypes } from "../../shared/constants/feedbackForm";
import OrderFeedbackFormSubmited from "../../components/FeedbackForm/OrderFeedbackFormSubmited";

class OrderHelp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      feedbackForms: null,
      selectedFeedbackForm: null,
      submitedSucessData: null,
    };
  }

  componentDidMount() {
    this.getOrderFeedbackForms();
  }

  getOrderFeedbackForms = async () => {
    const { match } = this.props;
    const { code } = match.params;
    const response = await getFeedbackForms(feedbackFormTypes.ORDER_FEEDBACK, code);
    const { feedbackForms } = response.data;
    this.setState({
      feedbackForms,
    });
  }

  onReturnClick = () => {
    const { history } = this.props;
    const { selectedFeedbackForm, submitedSucessData } = this.state;
    if (submitedSucessData) {
      this.setState({
        selectedFeedbackForm: null,
        submitedSucessData: null,
      });
      history.goBack();
    } else if (selectedFeedbackForm) {
      this.setState({
        selectedFeedbackForm: null,
        submitedSucessData: null,
      });
    } else {
      history.goBack();
    }
  }

  handleSubmited = (response) => {
    this.setState({
      submitedSucessData: response,
    });
  }

  setSelectedForm = (form) => {
    this.setState({
      selectedFeedbackForm: form,
    });
  }

  renderContent = () => {
    const { selectedFeedbackForm, feedbackForms, submitedSucessData } = this.state;
    const { match } = this.props;
    const { code } = match.params;
    if (feedbackForms === null) {
      return <Loader />;
    }
    if (selectedFeedbackForm !== null && submitedSucessData !== null) {
      return <OrderFeedbackFormSubmited onClose={this.onReturnClick} data={submitedSucessData} />;
    }
    if (selectedFeedbackForm !== null) {
      return <FeedbackForm form={selectedFeedbackForm} onFormSubmited={this.handleSubmited} orderCode={code} onCancel={this.onReturnClick} />;
    }

    return (
      <div>
        <div className={styles.subTitle}>What do you need help with?</div>
        <FeedbackFormList forms={feedbackForms} onSelectForm={this.setSelectedForm} />
      </div>
    );
  }

  render() {
    return (
      <div className={[styles.pageWrapper, 'text-dark dark:text-white mb-16'].join(' ')}>
        <div className={[styles.pageContainer, 'max-w-screen-desktop'].join(' ')}>
          <div className={styles.navBlock}>
            <img className="dark:image-negative" src={ReturnSVG} alt="Back" onClick={this.onReturnClick} />
          </div>
          <h1>Help</h1>
          { this.renderContent() }
        </div>
      </div>
    );
  }
}

OrderHelp.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(OrderHelp);
