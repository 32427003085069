/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-empty */
import React, { Component } from 'react';
import _ from 'underscore';
import styles from '../Contact.module.css';
import * as storeRepo from '../../../shared/repos/graphql/store';
import InputSearchBox from '../../../components/InputSearchBox/InputSearchBox';
import PropTypes from "prop-types";

class StoreField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: []
    };
  }

  componentDidMount() {
    this.getStores();
  }

  async getStores() {
    try {
      const response = await storeRepo.regularStores();

      const stores = _.pluck(response.data.regularStores, "name").sort();

      this.setState({
        stores
      });
    } catch (exception) {}
  }

  setStore = (store) => {
    const { component } = this.props;
    component.setState({
      store
    });
  };

  render() {
    const { component, isRequired, prompt } = this.props;
    const { store } = component.state;
    const { stores } = this.state;
    const storesList = stores.map(item => {
      return {
        label: item,
        value: item
      };
    });
    let promptItem = null;
    if (prompt) {
      promptItem = {
        label: prompt,
        value: null
      };
      storesList.unshift(promptItem);
    }
    const label = isRequired ? "Bakery*" : "Bakery";
    return (
      <div className={styles.dropDownWrapper}>
        <InputSearchBox
          prompt={prompt}
          label={label}
          items={storesList}
          onSelectItem={this.setStore}
          selectedItem={store}
        />
      </div>
    );
  }
}

StoreField.propTypes = {
  component: PropTypes.object.isRequired,
  isRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  prompt: PropTypes.string,
}

StoreField.defaultProps = {
  isRequired: false,
  prompt: null,
}

export default StoreField;
