import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// REDUX
import { connect } from "react-redux";

// ROUTER
import { withRouter } from 'react-router-dom';

import EasyMDE from "easymde";
import styles from './CmsContentEditModal.module.css';
import * as elementsActions from "../../redux/actions/elements";
import { updateCmsData, uploadCmsFile } from "../../shared/repos/graphql/cms";

export async function uploadImage(file, onSuccess, onError) {
  const result = await uploadCmsFile({
    data: {
      file,
    }
  });

  if (!result.data.uploadCmsFile || result.data.uploadCmsFile.indexOf('http') === -1) {
    onError(result.data.uploadCmsFile);

    return;
  }

  onSuccess(result.data.uploadCmsFile);
}

function CmsContentEditModal({ value, id, setModalObject, onUpdate }) {
  const [updating, setUpdating] = useState();
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    const editorEl = document.getElementById('cms-edit-content');

    const instance = new EasyMDE({
      element: editorEl,
      minHeight: '200px',
      uploadImage: true,
      imageMaxSize: (1024 * 1024), // 1 MB
      toolbar: [
        'bold',
        'italic',
        'heading',
        'heading-3',
        'link',
        'unordered-list',
        'ordered-list',
        'code',
        'quote',
        'strikethrough',
        'upload-image',
        'preview',
      ],
      imagePathAbsolute: true,
      imageUploadFunction: uploadImage,
    });

    setEditor(instance);
  }, []);

  const updateCmsContent = async () => {
    setUpdating(true);

    await updateCmsData({
      data: {
        cmsKeyValueId: id,
        value: editor.value(),
      }
    });

    setUpdating(false);

    onUpdate(editor.value());
    setModalObject(null);
  };

  const discardModal = () => {
    setUpdating(false);
    setModalObject(null);
  };

  return (
    <div className={styles.cmsContentEditModalContent}>
      <h3>Update Cms Content</h3>
      <div>
        <textarea id="cms-edit-content">{value}</textarea>
      </div>
      <div className={styles.cmsContentEditModalActions}>
        <button className="discardBtn" onClick={() => discardModal()} type='button'>Discard</button>
        <button
          className="updateCmsBtn"
          disabled={updating}
          onClick={() => updateCmsContent()}
          type='button'
        >
          { updating ? 'Updating...' : 'Update' }
        </button>
      </div>
    </div>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value))
});

CmsContentEditModal.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  setModalObject: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default withRouter(connect(null, mapDispatchToProps)(CmsContentEditModal));
