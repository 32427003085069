/* eslint-disable import/prefer-default-export */

export const setUserToken = value => ({
  type: 'SET_USER_TOKEN',
  value
});

export const setUserInfo = value => ({
  type: 'SET_USER_INFO',
  value
});

export const setSelectedStore = value => ({
  type: 'SET_SELECTED_STORE',
  value
});

export const setSelectedStoreHours = value => ({
  type: 'SET_SELECTED_STORE_HOURS',
  value
});

export const setSelectedMapStore = value => ({
  type: 'SET_SELECTED_MAP_STORE',
  value
});

export const setUserOrderMethod = value => ({
  type: 'SET_USER_ORDER_METHOD',
  value
});

export const setUserAddress = value => ({
  type: 'SET_USER_ADDRESS',
  value
});

export const setUserId = value => ({
  type: 'SET_USER_ID',
  value
})

export const setEndpoint = value => ({
  type: 'SET_ENDPOINT',
  value
})

export const setUserCart = value => ({
  type: 'SET_USER_CART',
  value
});

export const setUserCartId = value => ({
  type: 'SET_USER_CART_ID',
  value
});

export const setUserZoneId = value => ({
  type: 'SET_USER_ZONE_ID',
  value
});


export const setOrderCompleted = value => ({
  type: 'SET_ORDER_COMPLETED',
  value
});

export const saveAddress = value => ({
  type: 'SAVE_ADDRESS',
  value
});

export const setUserLocation = value => ({
  type: 'SET_USER_LOCATION',
  value
});

export const setBetaCustomer = value => ({
  type: 'SET_BETA_CUSTOMER',
  value
});

export const setDoughProMember = value => ({
  type: 'SET_DOUGH_PRO_MEMBER',
  value
});

export const setAlertRecipientPhone = value => ({
  type: 'SET_ALERT_RECIPIENT_PHONE',
  value
});

export const setAlertSenderPhone = value => ({
  type: 'SET_ALERT_SENDER_PHONE',
  value
});

export const setLastCompletedOrderCode = value => ({
  type: 'SET_LAST_COMPLETED_ORDER_CODE',
  value
});

export const resetUserData = () => ({
    type: 'RESET',
});

export const resetWithoutToken = () => ({
    type: 'RESET_WITHOUT_TOKEN',
});

export const setPermissions = (value) => ({
  type: 'SET_PERMISSIONS',
  value
});

export const setCmsEditMode = (value) => ({
  type: 'SET_CMS_EDIT_MODE',
  value
});
