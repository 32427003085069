/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./OrderDetailsWidget.module.css";
import { getOrderTypeString } from "../../shared/utilities/orders";
import CarSVG from './imgs/car.svg';
import HouseSVG from './imgs/house.svg';
import SpoonSVG from './imgs/forkknife.svg';
import DriverSVG from './imgs/driver.svg';
import { deliveryStatuses, orderDetailsWidgetModes, orderExperienceSources, orderTypeStrings } from "../../shared/constants/order";
import OrderDetailsDelivery from "./OrderDetailsDelivery";
import OrderDetailsPickup from "./OrderDetailsPickup";
import Switch from '../Switch/Switch';
import { updateOrderDetails } from "../../shared/repos/graphql/order";
import SimpleModal from "../Modal/SimpleModal";
import SmileRatingWidget from "../OrderExperience/SmileRatingWidget";
import ExitIconSVG from '../Modal/imgs/exit-icon.svg';
import modalStyles from '../Modal/Modal.module.css';
import { getOrderExperienceBySource, isOrderExpired } from "../../shared/utilities/common";
import * as elementsActions from "../../redux/actions/elements";

class OrderDetailsWidget extends Component {
  constructor(props) {
    super(props);

    const { order } = props;
    this.state = {
      orderTypeString: getOrderTypeString(order),
      mode: orderDetailsWidgetModes.view,
      isDataChanged: false,
      isFormPending: false,
      driverRating: null,
      showRatingModal: false,
      editOrderData: {
        showContactless: false,
        isContactlessDelivery: true,
        includeSpoon: false,
        deliveryTime: null,
        telephone: null,
        comment: null,
        shippingAddress2: null
      },
      editOrderErrors: {
        TELEPHONE_VALIDATION_ERROR: null,
        DELIVERY_TIME_ERROR: null,
        COMMON_VALIDATION_ERROR: null
      }
    };
  }

  componentDidMount() {
    const { order } = this.props;
    const driverRating = getOrderExperienceBySource(order, orderExperienceSources.RATE_DRIVER) || null;
    this.setState({
      editOrderData: this.resetEditOrderData(),
      editOrderErrors: this.resetEditOrderErrors(),
      driverRating,
    });
  }

  resetEditOrderData = () => {
    const { order, selectedStore } = this.props;
    const resetedData = {
      showContactless: selectedStore?.showContactless,
      isContactlessDelivery: !!order.isContactlessDelivery,
      includeSpoon: !!order.includeSpoon,
      deliveryTime: order.deliveryTime,
      telephone: order.recipient.phone,
      comment: order.comment,
      shippingAddress2: order.shippingAddress2
    };
    return resetedData;
  };

  resetEditOrderErrors = () => {
    return {
      TELEPHONE_VALIDATION_ERROR: null,
      DELIVERY_TIME_ERROR: null,
      COMMON_VALIDATION_ERROR: null
    };
  };

  onChageSpoonNeeded = () => {
    const { editOrderData } = this.state;
    let comment = editOrderData.comment;
    if (editOrderData.includeSpoon && comment) {
      comment = comment
        .replace("Message: . Add Spoon.", "")
        .replace(". Add Spoon.", "");
    }
    this.onChageOrderData({
      includeSpoon: !editOrderData.includeSpoon,
      comment
    });
  };

  onDriverRatingSubmited = (data) => {
    const { onOrderUpdated, order, setModalObject } = this.props;

    const driverRatingExisting = getOrderExperienceBySource(order, orderExperienceSources.RATE_DRIVER);
    let orderExperiences = order.orderExperiences || [];
    if (driverRatingExisting) {
      orderExperiences = orderExperiences.map((item) => {
        if (item.source === driverRatingExisting.source) {
          return data;
        }
        return item;
      });
    } else {
      orderExperiences.push(data);
    }
    this.setState({
      showRatingModal: false,
      driverRating: data,
    });
    setModalObject({
      title: 'Thank you for your feedback!',
      description: 'Your suggestions help us improve the experience for all Insomniacs.',
    });
    onOrderUpdated({ ...order, orderExperiences });
  };

  onRatingModalClose = () => {
    this.setState({
      showRatingModal: false,
    });
  };

  handleEditOrder = async () => {
    const { order, onOrderUpdated } = this.props;
    const { editOrderData, isFormPending, mode } = this.state;
    let errorsData = this.resetEditOrderErrors();
    if (isFormPending === false) {
      this.setState({
        isFormPending: true,
        editOrderErrors: errorsData
      });
      let modeAfterUpdate = mode;
      try {
        const orderResponce = await updateOrderDetails(
          order.code,
          editOrderData
        );
        if (orderResponce?.data?.updateOrder) {
          onOrderUpdated(orderResponce.data.updateOrder);
          modeAfterUpdate = orderDetailsWidgetModes.view;
        }
      } catch (e) {
        const { graphQLErrors } = e;
        if (graphQLErrors) {
          errorsData = this.handleValiationErrors(graphQLErrors);
        }
      }
      this.setState({
        isFormPending: false,
        editOrderErrors: errorsData,
        editOrderData:
          mode !== modeAfterUpdate ? this.resetEditOrderData() : editOrderData,
        mode: modeAfterUpdate
      });
    }
  };

  handleValiationErrors = errors => {
    const editOrderErrors = this.resetEditOrderErrors();
    const reason = errors[0]?.extensions?.reason;
    if (reason && Object.keys(editOrderErrors).includes(reason)) {
      editOrderErrors[reason] = errors[0].message;
    } else {
      editOrderErrors.COMMON_VALIDATION_ERROR = errors[0].message;
    }

    return editOrderErrors;
  };

  getSpoonText = () => {
    const { editOrderData } = this.state;
    return editOrderData.includeSpoon ? "Spoon needed" : "Spoon not needed";
  };

  toggleMode = () => {
    const { mode } = this.state;
    this.setState({
      editOrderData: this.resetEditOrderData(),
      editOrderErrors: this.resetEditOrderErrors(),
      mode:
        mode === orderDetailsWidgetModes.view
          ? orderDetailsWidgetModes.edit
          : orderDetailsWidgetModes.view
    });
  };

  onChageOrderData = data => {
    const { editOrderData } = this.state;

    const totalData = {
      ...editOrderData,
      ...data
    };
    const dataChaged = this.checkDataChaged(totalData);
    this.setState({
      editOrderData: totalData,
      isDataChanged: dataChaged
    });
  };

  checkDataChaged = data => {
    const { order } = this.props;
    return (
      data.isContactlessDelivery !== !!order.isContactlessDelivery ||
      data.includeSpoon !== !!order.includeSpoon ||
      data.deliveryTime !== order.deliveryTime ||
      data.telephone !== order.recipient.phone ||
      data.shippingAddress2 !== order.shippingAddress2 ||
      data.comment !== order.comment
    );
  };

  renderOrderDetails = () => {
    const { order } = this.props;
    const {
      orderTypeString,
      mode,
      editOrderData,
      editOrderErrors
    } = this.state;
    return orderTypeString === orderTypeStrings.delivery ? (
      <OrderDetailsDelivery
        mode={mode}
        order={order}
        editOrderErrors={editOrderErrors}
        editOrderData={editOrderData}
        onChange={this.onChageOrderData}
      />
    ) : (
      <OrderDetailsPickup
        mode={mode}
        order={order}
        editOrderErrors={editOrderErrors}
        editOrderData={editOrderData}
        onChange={this.onChageOrderData}
      />
    );
  };

  renderEditButton = () => {
    const { order } = this.props;
    const { mode } = this.state;
    return order?.status?.status <= deliveryStatuses.DRIVER_ASSIGNED ? (
      <button
        className={`${styles.orderDetailsEditButton} h-11 border-dark rounded-[40px] bg-white dark:bg-button text-dark`}
        onClick={() => this.toggleMode()}
        type="button"
      >
        {mode === orderDetailsWidgetModes.view ? "Edit" : "Reset"}
      </button>
    ) : null;
  };

  renderTitlePickup = () => {
    const { order } = this.props;
    const isNotCompleted = order?.status?.status < deliveryStatuses.DRIVER_RETURNED;
    const pickupText = isNotCompleted
      ? 'Change your pickup details until your order is boxed.'
      : '';
    return (
      <div
        className={[
          styles.orderDetailsLine,
          styles.orderDetailsLineTitle,
          styles.borderBottom
        ].join(" ")}
      >
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={CarSVG} alt="car" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Pickup Details</div>
          <div className={styles.orderDetailsSubtitle}>
            {pickupText}
          </div>
        </div>
        <div className={styles.buttonCell}>{this.renderEditButton()}</div>
      </div>
    );
  };

  renderTitleDelivery = () => {
    const { order } = this.props;
    const isNotCompleted = order?.status?.status < deliveryStatuses.DRIVER_RETURNED;
    const deliveryText = isNotCompleted
      ? 'Change your delivery details until your order is boxed.'
      : '';
    return (
      <div
        className={[
          styles.orderDetailsLine,
          styles.orderDetailsLineTitle,
          styles.borderBottom
        ].join(" ")}
      >
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={HouseSVG} alt="house" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Details</div>
          <div className={styles.orderDetailsSubtitle}>
            { deliveryText }
          </div>
        </div>
        <div className={styles.buttonCell}>{this.renderEditButton()}</div>
      </div>
    );
  };

  renderTitleLine = () => {
    const { orderTypeString } = this.state;

    return orderTypeString === orderTypeStrings.delivery
      ? this.renderTitleDelivery()
      : this.renderTitlePickup();
  };

  renderSpoonLine = () => {
    const { editOrderData, mode } = this.state;
    const { order } = this.props;
    const isNotCompleted = order?.status?.status < deliveryStatuses.DRIVER_RETURNED;
    return  isNotCompleted ? (
      <div className={[styles.orderDetailsLine, styles.borderTop].join(" ")}>
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={SpoonSVG} alt="spoon" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Spoon</div>
          <div className={styles.orderDetailsSubtitle}>
            {this.getSpoonText()}
          </div>
        </div>
        <div className={styles.buttonCell}>
          <Switch
            disabled={mode === orderDetailsWidgetModes.view}
            on={editOrderData.includeSpoon}
            onClick={() => this.onChageSpoonNeeded()}
          />
        </div>
      </div>
    ) : null;
  };

  renderSaveButton = () => {
    const { mode, isDataChanged, isFormPending, editOrderErrors } = this.state;
    const buttonText = isFormPending ? "Saving..." : "Save";
    return mode === orderDetailsWidgetModes.edit ? (
      <div className="text-center">
        <button
          disabled={!isDataChanged || isFormPending}
          className={`${styles.orderDetailsSaveButton} h-11 rounded-[40px] bg-dark dark:bg-button text-white font-filsonProBold`}
          onClick={() => this.handleEditOrder()}
          type="button"
        >
          {buttonText}
        </button>
        {editOrderErrors.COMMON_VALIDATION_ERROR ? (
          <div className={`${styles.formError} text-center`}>
            {editOrderErrors.COMMON_VALIDATION_ERROR}
          </div>
        ) : null}
      </div>
    ) : null;
  };

  renderRateYourDriverButton = () => {
    const { order } = this.props;
    const isExpired = isOrderExpired(order);
    const isRateButtonAvailable = !isExpired && order?.status?.status >= deliveryStatuses.DRIVER_RETURNED;
    return isRateButtonAvailable
      ? (
        <button
          type="button"
          className={[styles.driverButton, `bg-dark dark:bg-button text-white py-4 flex items-center justify-center`].join(' ')}
          onClick={() => this.setState({ showRatingModal: true })}
        >
Rate Driver
        </button>
)
      : null;
  };

  renderDriverSection = () => {
    const { order } = this.props;
    const { orderTypeString } = this.state;
    const { deliveryStatus } = order;
    const isBlockAvailable =
      orderTypeString === orderTypeStrings.delivery &&
      !!deliveryStatus?.driverFirstName &&
      order?.status?.status > deliveryStatuses.DRIVER_ASSIGNED;
    return isBlockAvailable ? (
      <div
        className={[
          styles.orderDetailsLine,
          styles.orderDetailsLineTitle,
          styles.borderBottom
        ].join(" ")}
      >
        <div className={styles.iconCell}>
          <img className="dark:image-negative" src={DriverSVG} alt="driver" />
        </div>
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Your Driver</div>
          <div className={styles.orderDetailsSubtitle}>
            {deliveryStatus.driverFirstName}
          </div>
        </div>
        <div className={[styles.buttonCell].join(' ')}>
          { this.renderRateYourDriverButton() }
        </div>
      </div>
    ) : null;
  };

  renderRatingModal = () => {
    const { showRatingModal, driverRating } = this.state;
    const { order } = this.props;
    const { deliveryStatus } = order;
    return showRatingModal ? (
      <SimpleModal>
        <div className={[
          modalStyles.simpleModalContainer,
          'dark:bg-dark',
        ].join(' ')}
        >
          <div className={[
            styles.orderDetailsLine,
            styles.orderDetailsLineTitle,
            styles.borderBottom
          ].join(" ")}
          >
            <div className={styles.contentCell}>
              <div className={modalStyles.rateModalTitle}>Rate your driver</div>
              <div className={modalStyles.rateModalSubTitle}>
                { `How was your experience with your driver ${deliveryStatus.driverFirstName}?` }
              </div>
            </div>
            <div className={modalStyles.closeWrapper}>
              <img className="dark:image-negative" onClick={this.onRatingModalClose} src={ExitIconSVG} alt="Close" />
            </div>
          </div>
        </div>
        <SmileRatingWidget
          defaultData={driverRating}
          orderCode={order.code}
          className={modalStyles.simpleModalContainer}
          source={orderExperienceSources.RATE_DRIVER}
          onSubmited={this.onDriverRatingSubmited}
        />
      </SimpleModal>
    ) : null;
  }

  render() {
    return (
      <div className={`${styles.orderDetailsContainer}`}>
        {this.renderDriverSection()}
        {this.renderTitleLine()}
        {this.renderOrderDetails()}
        {this.renderSpoonLine()}
        {this.renderSaveButton()}
        {this.renderRatingModal()}
      </div>
    );
  }
}

OrderDetailsWidget.propTypes = {
  order: PropTypes.object.isRequired,
  onOrderUpdated: PropTypes.func,
  setModalObject: PropTypes.func,
  selectedStore: PropTypes.shape({}),
};

OrderDetailsWidget.defaultProps = {
  onOrderUpdated: () => {},
  setModalObject: () => {},
  selectedStore: { id: "" },
};

export const mapStateToProps = (state) => {
  const { selectedStore } = state.user;
    return {
      selectedStore
    }
}

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions .setModalObject(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetailsWidget));
