/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./FeedbackForm.module.css";

class OrderFeedbackFormSubmited extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getMessage = (message) => {
    return message || (`Someone from Insomnia will reach out within <b>48</b> hours.`);
  }

  render() {
    const { className, data, title, subTitle, onClose } = this.props;
    return (
      <div className={[styles.successComponentContainer, className].join(' ')}>
        <div className={styles.successComponentTitle}>{ title }</div>
        <div className={styles.successComponentSubTitle}>{ subTitle }</div>
        <div className={styles.successComponentMessage} dangerouslySetInnerHTML={{__html: this.getMessage(data?.message)}}></div>
        <div className={styles.successComponentButtons}>
          <button className={[
              `bg-dark dark:bg-button text-white py-4 flex items-center justify-center`,
              styles.successComponentClose].join(' ')}
              type="button"
              onClick={onClose}
              >Continue</button>
        </div>
      </div>
    );
  }
}

OrderFeedbackFormSubmited.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      success: PropTypes.bool,
      message: PropTypes.string,
    }),
  ], PropTypes.oneOf([null])),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

OrderFeedbackFormSubmited.defaultProps = {
    onClose: () => {},
    title: 'Submission received',
    subTitle: 'Thank you for letting us know',
    className: '',
    data: null,
};

export default OrderFeedbackFormSubmited;
