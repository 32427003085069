import React from "react";
import PropTypes from "prop-types";
// ROUTER
import { withRouter } from 'react-router-dom';

// REDUX
import { connect } from "react-redux";

import Switch from '../../Switch/Switch';
import style from './CmsEditSwitch.module.css';
import * as userActions from "../../../redux/actions/user";

function CmsEditSwitch({ showOnMobileView, cmsEditMode, setCmsEditMode }) {
  return (
    <div className={`${showOnMobileView ?  style.cmsEditModeMobile : style.cmsEditMode}`}>
      <span>Cms</span>
      &nbsp;
      <Switch
        on={cmsEditMode}
        onClick={() => setCmsEditMode(!cmsEditMode)}
      />
    </div>
  );
}

export const mapStateToProps = (state) => {
  const {
    cmsEditMode,
  } = state.user;

  return {
    cmsEditMode,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setCmsEditMode: value => dispatch(userActions.setCmsEditMode(value)),
});

CmsEditSwitch.propTypes = {
  cmsEditMode: PropTypes.bool.isRequired,
  setCmsEditMode: PropTypes.func.isRequired,
  showOnMobileView: PropTypes.bool,
}

CmsEditSwitch.defaultProps = {
  showOnMobileView: false,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CmsEditSwitch));
