/* eslint-disable import/prefer-default-export */
/* Testing purposes */
import gql from 'graphql-tag';

export const ME_QUERY = gql`
  query ME {
    me {
      id
      firstname
      lastname
      telephone
      prefixTelephone
      phoneTelephone
      email
      birthday
      telephoneVerified
      telephoneVerificationRequired
      hasPassword
      isStudent
      schoolId
      schoolName
      graduationYear
      favoriteStores{
          id
          name
          address
      }
      permissions {
        category
        value
      }
      customerCards {
          id
          last4
          cardType
          transactionId
          paymentMethodId
          store {
              id
              name
          }
      }
      cards {
        last4
        cardType
        favorite
        transactionId
        paymentMethodId
        exp
      }
      isLoyaltyMember
        loyalty {
        id
        points
        rewards
        pointsUntilNextReward
        nextReward
        referralCode
      }
      allowPushNotifications
    }
  }
`;

export const ME_HEADER_QUERY = gql`
  query ME_HEADER_QUERY {
    me {
      id
      firstname
      lastname
      isBetaCustomer
      permissions {
        category
        value
      }
      loyalty {
        points
      }
      activeOrders(orderBy: { column: "created_at", order: "desc" }, pagination: { limit: 100, offset: 0 }) {
        totalCount
      }
    },
    doughProMember {
      active
      onGracePeriod
      expirationDate
      paymentMethod
      freeCookieCount
      freeCookieUsedAt
      freeCookieActiveDuration
      lastBillingDate
      lastBillingAmount
      giftcardNumber
      freeTraditionalProduct {
        id
      }
    }
  }
`;

export const ME_ORDERS_QUERY = gql`
  query ME_ORDERS {
    me {
      id
      activeOrders(orderBy: { column: "created_at", order: "desc" }, pagination: { limit: 100, offset: 0 }) {
        totalCount
        totalPages
        orders {
          id
          code
          orderMethod
          deliveryTime
          orderTypeId
          shippingAddress {
            address1
            lat
            lng
          }
          status {
            status
          }
          store {
            id
            address
            lat
            lng
            name
            phone
          }
        }
      }
      finishedOrders(orderBy: { column: "created_at", order: "desc" }, pagination: { limit: 5, offset: 0 }) {
        totalCount
        totalPages
        orders {
          id
          code
          orderMethod
          invoiceDate
          deliveryTime
          items {
            quantity
            id
            price
            product {
              hasAssortments
              title
              price
            }
            productOptions {
              id
              title
              quantity
              optionPrice
            }
          }
          orderTypeId
          shippingAddress {
            address1
            lat
            lng
          }
          status {
            status
          }
          store {
            id
            address
            currency {
              name,
              code,
              symbol
            }
            lat
            lng
            name
            phone
          }
          totals {
            title
            value
          }
        }
      }
    }
  }
`;

export const ME_ACTIVE_ORDERS_QUERY = gql`
  query ME_ACTIVE_ORDERS($orderBy: OrderByInput, $pagination: PaginationInput) {
    me {
      id
      activeOrders(orderBy: $orderBy, pagination: $pagination) {
        totalCount
        totalPages
        orders {
          id
          code
          deliveryTime
          orderMethod
          deliveryStatus {
            status
            lat
            lon
            estimatedPickupTime
            estimatedPickupTimeMax
            estimatedDropoffTime
            estimatedDropoffTimeMax
          }
          orderTypeId
          shippingAddress {
            address1
            lat
            lng
          }
          status {
            status
          }
          store {
            id
            address
            lat
            lng
            name
            phone
          }
        }
      }
    }
  }
`;

export const ME_FINISHED_ORDERS_QUERY = gql`
  query ME_FINISHED_ORDERS($orderBy: OrderByInput, $pagination: PaginationInput) {
    me {
      id
      finishedOrders(orderBy: $orderBy, pagination: $pagination) {
        totalCount
        totalPages
        orders {
          id
          code
          invoiceDate
          isCancelled
          isRefunded
          deliveryTime
          orderMethod
          items {
            quantity
            id
            price
            product {
              hasAssortments
              title
              price
            }
            productOptions {
              id
              title
              quantity
              optionPrice
            }
          }
          orderTypeId
          shippingAddress {
            address1
            lat
            lng
          }
          status {
            status
          }
          store {
            id
            address
            currency {
              name,
              code,
              symbol
            }
            lat
            lng
            name
            phone
          }
          totals {
            title
            value
          }
        }
      }
    }
  }
`;

export const LOGIN_QUERY = gql`
         query LOGIN_QUERY(
           $username: String
           $password: String
           $facebookId: String
           $gRepcaptchaResponse: String
           $phoneNumber: phoneNumber
         ) {
           login(
             data: {
               username: $username
               password: $password
               facebookId: $facebookId
               gRepcaptchaResponse: $gRepcaptchaResponse
               phoneNumber: $phoneNumber
             }
           ) {
              token,
              customer {
                id
                firstname
                lastname
                telephone
                prefixTelephone
                phoneTelephone
                email
                birthday
                telephoneVerified
                telephoneVerificationRequired
             }
           }
         }
       `;


export const ME_VERIFY_PHONE_NUMBER = gql`
       query ME_VERIFY_PHONE_NUMBER(
         $email: String!
         $password: String!
         $phoneNumber: phoneNumber!
       ) {
        verifyPhone(
           data: {
            email: $email
            password: $password
            phoneNumber: $phoneNumber
           }
         ) {
          id
         }
       }
     `;

export const SIGNUP_CUSTOMER_MUTATION = gql`
  mutation SIGNUP_CUSTOMER_MUTATION($email: String!, $password: String!, $gRepcaptchaResponse: String, $newsletter: Boolean!, $subscriptionIds: [Int!]) {
    signupCustomer(email: $email, password: $password,  gRepcaptchaResponse: $gRepcaptchaResponse, newsletter: $newsletter, subscriptionIds: $subscriptionIds) {
      token
    }
  }
`;

export const SIGNUP_CUSTOMER_MUTATION_V2 = gql`
  mutation SIGNUP_CUSTOMER_MUTATION(
    $email: String!
    $firstName: String!
    $lastName: String!
    $code: String!
    $phone: String!
    $gRepcaptchaResponse: String
    $newsletter: Boolean!
    $subscriptionIds: [Int!],
    $inviteCode: String
  ) {
    signupCustomerV2(
      email: $email
      firstName: $firstName
      lastName: $lastName
      code: $code
      phone: $phone
      gRepcaptchaResponse: $gRepcaptchaResponse
      newsletter: $newsletter
      subscriptionIds: $subscriptionIds
      inviteCode: $inviteCode
    ) {
      customer {
        id
        firstname
        lastname
        phone
        code
        email
     }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation RESET_PASSWORD_MUTATION($email: String!) {
    resetPassword(where: { email: $email })
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($key: String, $newPassword: String, $confirmPassword: String) {
    changePassword(input: {
        key: $key,
        newPassword: $newPassword,
        confirmPassword: $confirmPassword
    })
  }
`;

export const UPDATE_USER_PUSH_NOTIFICATION = gql`
mutation updateCustomerNotifications(
  $allowPushNotifications: Boolean
) {
  updateCustomerNotifications(input: {
    allowPushNotifications: $allowPushNotifications
  }) {
    allowPushNotifications
  }
}
`
export const DEACTIVATE_USER_ACCOUNT = gql`
  mutation deleteCustomer {
    deleteCustomer
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateCustomer(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $telephone: String!,
    $phoneTelephone: String!,
    $prefixTelephone: String!,
    $birthday: Date,
    $isStudent: Boolean,
    $schoolId: Int,
    $graduationYear: Int
  ) {
    updateCustomer(
      input: {
          firstName: $firstName,
          lastName: $lastName,
          email: $email,
          telephone: $telephone,
          birthday: $birthday,
          phoneTelephone: $phoneTelephone,
          prefixTelephone: $prefixTelephone,
          isStudent: $isStudent,
          schoolId: $schoolId,
          graduationYear: $graduationYear
      }
    ) {
      email
    }
  }
`;

export const CONFIRM_CUSTOMER_TELEPHONE_OTP_MUTATION = gql`
  mutation confirmCustomerTelephoneOtp($telephoneOtp: String!, $code: String, $phone: String, $email: String, $password: String, $orderCode: String) {
    confirmCustomerTelephoneOtp(telephoneOtp: $telephoneOtp, code: $code, phone: $phone, email: $email, password: $password, orderCode: $orderCode) {
      telephoneVerified
      token
    }
  }
`;

export const CONFIRM_CUSTOMER_SIGNUP = gql`
  mutation confirmCustomerSignup($telephoneOtp: String!, $id: String, $orderCode: String) {
    confirmCustomerSignup(telephoneOtp: $telephoneOtp, id: $id,orderCode: $orderCode) {
      token,
      customer {
        id
        firstname
        lastname
        telephone
        prefixTelephone
        phoneTelephone
        email
        birthday
        telephoneVerified
        telephoneVerificationRequired
      }
    }
  }
`;

export const RESEND_CUSTOMER_TELEPHONE_OTP_MUTATION = gql`
  mutation resendCustomerTelephoneOtp {
    resendCustomerTelephoneOtp
  }
`;

export const RESEND_CUSTOMER_VERIFICATION_TELEPHONE_OTP_MUTATION = gql`
  mutation resendCustomerVerificationTelephoneOtp($code: String, $phone: String, $email: String, $password: String, $id: String) {
    resendCustomerVerificationTelephoneOtp(code: $code, phone: $phone, email: $email, password: $password, id: $id)
  }
`;

export const REMOVE_CUSTOMER_CARD_MUTATION = gql`
    mutation removeCustomerCard($cardId: ID!){
        removeCustomerCard(cardId: $cardId)
    }
`;

export const CHANGE_CUSTOMER_PASSWORD_MUTATION = gql`
    mutation changeCustomerPassword($data: ChangeCustomerPasswordInput!){
        changeCustomerPassword(data: $data)
    }
`;

export const ME_RECENT_ADDRESSES_QUERY = gql`
    query ME_RECENT_ADDRESESES {
        me {
            id
            favoriteStores{
              id
              countryId
              name
              slug
              address
              mailingAddress
              city
              state
              zip
              distanceToStore
              phone
              storefrontImage
              lat
              lng
              inDeliveryRange
              boundary
              status
              note
              storeType
              isPickupOpen
              isDeliveryOpen
              hours {
                type
                days {day, hour}
              }
              blurb
              promotionalText
            }
            recentAddresses {
                address {
                    address1
                    city
                    state
                    postcode
                    country
                    lat
                    lng
                    countryId
                    __typename
                }
                store {
                    id
                    name
                    __typename
                }
            }
        }
    }
`;

export const LOGOUT_CUSTOMER = gql`
    mutation LOGOUT_CUSTOMER($rememberToken: String!, $orderCode: String) {
        logoutCustomer(rememberToken: $rememberToken, orderCode: $orderCode)
    }
`;

export const LEAVE_CUSTOMER_LOYALTY_MUTATION = gql`
    mutation LEAVE_CUSTOMER_LOYALTY{
        leaveCustomerLoyalty
    }
`;
